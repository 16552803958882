<template>
    <section>
        <div class="section-content pb-32">
            <h1>Tools</h1>
            <div class="stack-list mb-8 -mx-2 flex flex-wrap">
                <div :key="index" v-for="(stack, index) in stacks" class="w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 p-2">
                    <div class="stack tile-wrapper w-full">
                        <div class="tile-content py-8 px-2">
                            <svg class="h-16 inline-block" viewBox="0 0 128 128" v-html="stack.path" />
                        </div>
                        <a :href="stack.url" target="_blank" class="tile-content inline-block button w-full py-2">
                            <p>{{ stack.name }} <i class="uil uil-external-link-alt"></i></p>
                        </a>
                    </div>
                </div>
            </div>
        </div>

    </section>
</template>

<script>
export default {
    name: 'Stacks',
    data() {
        return {
            stacks: [
                {
                    name: 'Figma',
                    url: 'https://www.figma.com/',
                    path: '<path d="M42.6834 128C48.3414 128 53.7676 125.752 57.7684 121.752C61.7692 117.751 64.0168 112.325 64.0168 106.667V85.3333H42.6834C37.0255 85.3333 31.5993 87.5809 27.5985 91.5817C23.5977 95.5825 21.3501 101.009 21.3501 106.667C21.3501 112.325 23.5977 117.751 27.5985 121.752C31.5993 125.752 37.0255 128 42.6834 128V128Z" fill="#0ACF83"/><path d="M21.3501 64C21.3501 58.3421 23.5977 52.9159 27.5985 48.9151C31.5993 44.9143 37.0255 42.6667 42.6834 42.6667H64.0168V85.3334H42.6834C37.0255 85.3334 31.5993 83.0857 27.5985 79.085C23.5977 75.0842 21.3501 69.658 21.3501 64V64Z" fill="#A259FF"/><path d="M21.3501 21.3333C21.3501 15.6783 23.5954 10.2546 27.5926 6.25428C31.5898 2.25398 37.0117 0.00441802 42.6668 0L64.0001 0V42.6667H42.6834C37.0255 42.6667 31.5993 40.4191 27.5985 36.4183C23.5977 32.4175 21.3501 26.9913 21.3501 21.3333V21.3333Z" fill="#F24E1E"/><path d="M64.0166 0H85.3499C91.0079 0 96.4341 2.24761 100.435 6.24839C104.436 10.2492 106.683 15.6754 106.683 21.3333C106.683 26.9913 104.436 32.4175 100.435 36.4183C96.4341 40.4191 91.0079 42.6667 85.3499 42.6667H64.0166V0Z" fill="#FF7262"/><path d="M106.683 64C106.683 69.658 104.436 75.0842 100.435 79.085C96.4341 83.0857 91.0079 85.3334 85.3499 85.3334C79.692 85.3334 74.2658 83.0857 70.265 79.085C66.2642 75.0842 64.0166 69.658 64.0166 64C64.0166 58.3421 66.2642 52.9159 70.265 48.9151C74.2658 44.9143 79.692 42.6667 85.3499 42.6667C91.0079 42.6667 96.4341 44.9143 100.435 48.9151C104.436 52.9159 106.683 58.3421 106.683 64V64Z" fill="#1ABCFE"/>'
                },
                {
                    name: 'Sketch',
                    url: 'https://www.sketch.com/',
                    path: '<defs><style>.cls-1{fill:#fdb300;}.cls-2{fill:#ea6c00;}.cls-3{fill:#fdad00;}.cls-4{fill:#fdd231;}.cls-5{fill:#feeeb7;}</style></defs><title>Artboard 12</title><g id="original"><path class="cls-1" d="M29.62,12.85,64,9.24l34.38,3.61L125,48.29,64,118.76,3,48.29Z"></path><polygon class="cls-2" points="27.71 48.29 64 118.76 3 48.29 27.71 48.29"></polygon><polygon class="cls-2" points="100.29 48.29 64 118.76 125 48.29 100.29 48.29"></polygon><polygon class="cls-3" points="27.71 48.29 100.29 48.29 64 118.76 27.71 48.29"></polygon><polygon class="cls-4" points="64 9.24 29.62 12.85 27.71 48.29 64 9.24"></polygon><polygon class="cls-4" points="64 9.24 98.38 12.85 100.29 48.29 64 9.24"></polygon><polygon class="cls-3" points="125 48.29 98.38 12.85 100.29 48.29 125 48.29"></polygon><polygon class="cls-5" points="64 9.24 27.71 48.29 100.29 48.29 64 9.24"></polygon><polygon class="cls-3" points="3 48.29 29.62 12.85 27.71 48.29 3 48.29"></polygon></g>'
                },
                {
                    name: 'Illustrator',
                    url: 'https://www.adobe.com/au/products/illustrator.html',
                    path: '<rect width="128" height="128" rx="20" fill="#2E0302"/><path fill-rule="evenodd" clip-rule="evenodd" d="M88.2039 39.27C88.2039 35.3359 90.9691 33 94.4721 33C98.2206 33 100.74 35.5202 100.74 39.27C100.74 43.3271 98.0977 45.54 94.3492 45.54C90.7847 45.5402 88.2039 43.3271 88.2039 39.27ZM89.6173 50.3365C89.064 50.3365 88.8797 50.5554 88.8797 50.9929H88.9412V94.0886C88.9412 94.526 89.1257 94.7994 89.7402 94.7994H99.2651C99.8795 94.7994 100.002 94.5805 100.002 94.1432V50.9929C100.002 50.5005 99.7564 50.3365 99.2651 50.3365H89.6173ZM58.4434 61.7642C59.2724 64.5772 60.0219 67.1199 60.5506 68.9858H45.9868C48.5677 60.5087 51.8244 49.6803 53.115 43.2816H53.2379C54.3022 47.7136 56.575 55.4252 58.4434 61.7642ZM38.6124 94.2526L43.4672 77.8457H63.1315L68.1706 94.3073C68.2932 94.6898 68.6007 94.7992 69.0923 94.7992H79.2933C79.7849 94.7992 79.9078 94.5256 79.7849 94.0883L60.2434 35.0781C60.1203 34.6407 59.936 34.5313 59.5059 34.5313H46.601C46.2938 34.5313 46.1095 34.6954 46.1095 35.0781C45.9866 37.7578 45.8023 38.6329 45.495 39.6172L28.0429 93.979C27.9198 94.6352 28.0429 94.7992 28.6574 94.7992H37.6908C38.3052 94.7992 38.4895 94.6898 38.6124 94.2526Z" fill="#F29E39"/>'
                },
                {
                    name: 'Photoshop',
                    url: 'https://www.adobe.com/au/products/photoshop.html',
                    path: '<rect width="128" height="128" rx="20" fill="#071E34"/><path d="M90.3492 56.462C85.5277 56.462 83.8801 58.6496 83.8801 60.4543C83.8801 62.4231 84.9785 63.7904 91.4478 66.7983C101.029 70.9548 104.02 74.9471 104.02 80.799C104.02 89.5493 96.5744 94.2526 86.5043 94.2526C81.1947 94.2526 76.6173 93.2681 73.9931 91.9011C73.566 91.737 73.505 91.4636 73.505 91.0259V82.9865C73.505 82.4397 73.8101 82.2754 74.2375 82.5491C78.0823 84.7915 82.4764 85.7757 86.5043 85.7757C91.3258 85.7757 93.3397 83.971 93.3397 81.5099C93.3397 79.5411 91.936 77.791 85.7718 74.9471C77.1055 71.2283 73.505 67.4547 73.505 61.1654C73.505 54.1103 79.6691 48.2586 90.3492 48.2586C95.5978 48.2586 99.2596 48.9697 101.274 49.7899C101.762 50.0633 101.884 50.5008 101.884 50.8836V58.3759C101.884 58.8134 101.579 59.087 100.968 58.9227C98.283 57.3919 94.3161 56.462 90.3492 56.462ZM37.8633 62.9701C39.2671 63.0795 40.3657 63.0795 42.8068 63.0795C49.9476 63.0795 56.6607 60.8373 56.6607 52.1417C56.6607 45.1962 51.8393 41.6958 43.7224 41.6958C41.2813 41.6958 38.962 41.8052 37.8633 41.8599V62.9701ZM27.0002 33.9298C27.0002 33.547 27.8548 33.2734 28.3427 33.2734C32.2489 33.1094 38.0467 33 44.0885 33C60.9938 33 67.5849 41.3128 67.5849 51.9227C67.5849 65.8138 56.3554 71.7751 42.5628 71.7751C40.2437 71.7751 39.4504 71.6657 37.8023 71.6657V92.6666C37.8023 93.1041 37.6193 93.3228 37.0701 93.3228H27.7325C27.2443 93.3228 27 93.1588 27 92.6666L27.0002 33.9298Z" fill="#54A8F8"/>'
                },
                {
                    name: 'After effects',
                    url: 'https://www.adobe.com/au/products/aftereffects.html',
                    path: '<rect width="128" height="128" rx="20" fill="#000657"/><path d="M94.2495 66.4308C97.3072 66.4308 98.3866 66.4308 98.6863 66.3215C98.6863 65.9387 98.8062 65.6651 98.8062 65.3916C98.8062 62.4384 97.2473 56.9695 91.0117 56.9695C85.2554 56.9695 82.7974 61.5632 82.1977 66.4308H94.2495ZM82.078 73.9232C82.1979 81.3064 86.0353 86.2832 95.149 86.2832C98.6866 86.2832 101.744 85.8459 104.922 84.588C105.342 84.424 105.642 84.4786 105.642 84.9708V91.807C105.642 92.3538 105.462 92.6274 105.043 92.9008C101.865 94.3226 97.9072 94.979 92.9908 94.979C77.1618 94.979 71.2257 84.3146 71.2257 72.447C71.2257 59.5402 78.5409 48.9852 91.432 48.9852C104.503 48.9852 109.06 58.9934 109.06 67.1421C109.06 69.7673 108.88 71.9002 108.58 72.9393C108.46 73.3768 108.281 73.5411 107.801 73.6504C106.602 73.8145 103.064 74.033 97.7876 74.033H82.078V73.9232ZM49.9403 68.3449C48.2614 62.2743 44.1841 49.0394 42.685 42.6407H42.5651C41.3062 49.0394 38.0082 59.8678 35.5499 68.3449H49.9403ZM33.0915 77.2048L28.3549 93.557C28.235 93.9943 28.0551 94.1585 27.4554 94.1585H18.6414C18.0419 94.1585 17.9217 93.9945 18.0419 93.3383L35.0701 39.0312C35.3698 38.0469 35.5497 37.2812 35.6696 34.5468C35.6696 34.164 35.8494 34 36.1492 34H48.7406C49.1602 34 49.3401 34.1094 49.4602 34.5468L68.527 93.4477C68.6469 93.8849 68.527 94.1585 68.0474 94.1585H58.1543C57.6747 94.1585 57.3747 93.9945 57.2548 93.6119L52.3381 77.205L33.0915 77.2048Z" fill="#989BF8"/>'
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.stack {
    @apply inline-block text-center;
}
</style>