<template>
    <section>
        <div class="section-content pb-32">
            <h1 class="mb-8">Favourite projects</h1>
            <div class="project-list">
                <Tile :key="project.name" v-for="(project) in projects" :value="project" />
            </div>
        </div>

    </section>
</template>

<script>
import projects from "@/assets/projects";
import Tile from '@/components/Tile'

export default {
    name: 'Projects',
    components: {
        Tile
    },
    data() {
        return {
            projects,
        };
    }
}
</script>

<style lang="scss" scoped>
.project-list {
    @apply flex flex-wrap -mx-4;
    .tile {
        @apply w-full p-4;
        @screen md {
            @apply w-1/2;
        }
    }
}
</style>