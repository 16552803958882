const skill = {
  logo: "Logo design",
  web: "Website design",
  ui: "UI design",
  ux: "UX",
  graphic: "Graphic design",
  motion: "Motion design",
  development: "Development",
  brand: "Branding",
  product: "Product Owning",
};

const stack = {
  vue: "Vue",
  react: `React`,
  expo: "Expo/React Native",
  nuxt: "Nuxt",
  PWA: "PWA",
  webpack: "Webpack",
  tailwind: "Tailwind CSS",
  scss: "SCSS",
  express: "Express",
  firebase: "Firebase",
  aws: "AWS",
  electron: "Electron",
  gameMaker: "Game Maker Studio",
  redbull: "A lot of Red Bull",
};

const projects = [
  {
    name: "lighthouse",
    title: "Lighthouse",
    recommended: true,
    website: "https://lighthouse.sigmaprime.io/",
    shortDescription: "A Ethereum consensus client, written in Rust.",
    description:
      "A website built with only a week & a half to spare before a conference.",
    skill: [skill.development, skill.web],
    stack: [stack.vue, stack.nuxt, stack.tailwind, stack.scss, stack.redbull],
  },
  {
    name: "phoenix",
    title: "Phoenix",
    recommended: false,
    shortDescription: "An AI chatbot builder design for none technical users.",
    description: "An internal tool for Always Here.",
    skill: [
      skill.product,
      skill.development,
      skill.logo,
      skill.ui,
      skill.ux,
      skill.graphic,
    ],
    stack: [
      stack.vue,
      stack.nuxt,
      stack.tailwind,
      stack.scss,
      stack.express,
      stack.aws,
    ],
  },
  {
    name: "always-here",
    title: "Always Here",
    recommended: false,
    website: "https://www.alwayshere.io/",
    shortDescription: " New Approach to Child Mental Health Post-COVID-19.",
    description: "Mental health and wellbeing companion apps for families.",
    skill: [
      skill.product,
      skill.development,
      skill.logo,
      skill.ui,
      skill.ux,
      skill.web,
      skill.graphic,
      skill.motion,
    ],
    stack: [
      stack.PWA,
      stack.vue,
      stack.nuxt,
      stack.tailwind,
      stack.scss,
      stack.express,
      stack.aws,
      stack.firebase,
    ],
  },
  {
    name: "tiny-cart",
    title: "Tiny Cart",
    recommended: true,
    website: "https://tinycart.co/",
    demo: "https://demo.joeltankard.com/tiny-cart",
    shortDescription: "Ecommerce packed into a button.",
    description:
      "A shopping cart & checkout system fit into a button. Ecommerce can now be added to any website with a single script. Allowing developers to create a unique shopping experience for their customers and clients without dealing with the hard stuff.",
    skill: [
      skill.development,
      skill.logo,
      skill.ui,
      skill.ux,
      skill.web,
      skill.graphic,
      skill.motion,
    ],
    stack: [
      stack.vue,
      stack.nuxt,
      stack.tailwind,
      stack.scss,
      stack.express,
      stack.aws,
      stack.firebase,
    ],
  },
  //   {
  //     name: "vantari",
  //     title: "Vantari VR",
  //     website: "https://app.vantarivr.com/",
  //     shortDescription: "Surgery performed in virtual reality",
  //     description: "",
  //   },
  {
    title: "Grow super - Website",
    name: "grow-super-website-2020",
    shortDescription: "A blocky, responsive website",
    description:
      'Grow super is a startup I used to work in. Their goal is to modernise superannuation. A good friend of mine, <a href="https://dribbble.com/benpaterson" target="_blank">Ben Paterson</a> designed a new website for the next phase of Grow\'s life.',
    skill: [skill.development],
    stack: [stack.vue, stack.tailwind, stack.scss],
    website: "https://demo.joeltankard.com/growsuper",
  },
  {
    name: "facetracker",
    title: "Face Tracker",
    shortDescription: "Re-thinking video calls",
    description:
      "A face tracking app made using Electron. Created for video calls without requiring the video.",
    skill: [skill.development, skill.graphic],
    stack: [stack.vue, stack.electron, stack.tailwind, stack.scss],
    repo: "https://github.com/JoelTankard/mask-app",
  },
  //   {
  //     title: "Startnames",
  //     shortDescription: "Domain titles for side projects",
  //     name: "startnames",
  //   },
  {
    title: "Grow super - App",
    recommended: true,
    shortDescription: "Superannuation for millennials",
    name: "grow-super-v2",
    website: "https://www.grow.inc/",
    skill: [
      skill.development,
      skill.logo,
      skill.ui,
      skill.ux,
      skill.web,
      skill.graphic,
    ],
    stack: [
      stack.react,
      stack.expo,
      stack.vue,
      stack.webpack,
      stack.scss,
      stack.express,
      stack.aws,
    ],
  },
  {
    title: "Grow super - Re-brand",
    name: "grow-super-rebrand",
    shortDescription: "Modernising the Grow Super brand",
    description: "Re-branding Grow super's current logo & future web projects.",
    skill: [skill.graphic, skill.logo, skill.brand],
  },
  //   {
  //     title: "DSD Studios",
  //     shortDescription: "Website re-design",
  //     name: "dsd-studios",
  //   },
  {
    name: "upproval",
    title: "Upproval",
    recommended: true,
    shortDescription: "Design tool used by Accor hotels",
    skill: [
      skill.development,
      skill.logo,
      skill.ui,
      skill.ux,
      skill.web,
      skill.graphic,
    ],
    stack: [stack.react, stack.scss, stack.express, stack.aws],
  },
  //   {
  //     title: "uPaged",
  //     shortDescription: "Nurses on demand",
  //     name: "upaged",
  //   },
  //   {
  //     title: "HomeVue",
  //     shortDescription: "Home buying made easy with Stone Money",
  //     name: "homevue",
  //   },
  {
    title: "Waffle run",
    shortDescription: "A Mobile Game for iOS and Android",
    description:
      "Lessons Learned from Building and Launching my First Product from Scratch",
    name: "waffle-run",
    skill: [
      skill.development,
      skill.logo,
      skill.ui,
      skill.ux,
      skill.graphic,
      skill.motion,
    ],
    stack: [stack.gameMaker],
  },
];

export default projects;
