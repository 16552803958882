<template>
<div id="app" class="bg-black absolute inset-0" >
  <div :class="['main-app-panel', { 'project-open' : $route.meta.id }]"></div>
  <project-modal />
  <div :class="[ 'main', { 'project-open' : $route.meta.id }, { 'overflow-scroll': pageReady }]" @scroll="({ target }) => scrollTop = target.scrollTop">
    <Menu :scroll-top="scrollTop" :hide-bg="!!$route.meta.id" :class="['transform', { ' -translate-y-full': !pageReady }]">
      <router-link to="/"><Logo class="logo" /></router-link>
      <div class="inline-block link-group">
        <a href="mailto:hello@joeltankard.com">Contact</a>
        <a href="https://www.twitter.com/joeltankard" target="_blank"><i class="uil uil-twitter"></i></a>
        <a href="https://www.github.com/joeltankard" target="_blank"><i class="uil uil-github"></i></a>
        <a href="https://www.dribbble.com/joeltankard" target="_blank"><i class="uil uil-dribbble"></i></a>
      </div>
    </Menu>
    <div>
        <hero :ready="pageReady"/>
        <logos />
        <work/>
        <stacks />
        <tools />
        <contact-section v-if="!$route.meta.id" />
    </div>
  </div>
</div>
</template>

<script>
import Menu from '@/components/Menu';
import Logo from '@/components/Logo';
import ProjectModal from '@/components/ProjectModal';
import Hero from '@/components/sections/Hero';
import Logos from '@/components/sections/Logos';
import Work from '@/components/sections/Work';
import Stacks from '@/components/sections/Stacks';
import Tools from '@/components/sections/Tools';
import ContactSection from '@/components/ContactSection';


export default {
  name: 'App',
  components: {
    Menu,
    Logo,
    ProjectModal,
    Hero,
    Logos,
    Work,
    Stacks,
    Tools,
    ContactSection
  },
  data() {
    return {
      scrollTop: 0,
      pageReady: this.$route.meta.id
    }
  },
  mounted() {
    document.onreadystatechange = () => {
      if (document.readyState == "complete") {
        setTimeout(() => {
          this.pageReady = true 
        }, 100);
      }
    }
  }
}
</script>

<style lang="scss">
.logo {
    @apply w-12 h-12 inline-block;
  }
.main-app-panel {
  @apply absolute inset-0 rounded-3xl transform scale-125 bg-white origin-top;
   transition: .2s transform ease;
   &.project-open {
    transform: scale(0.90) translateY(20px);
   
  }
}
.main {
  @apply transform  absolute inset-0 rounded-none origin-top;
  transition: .2s transform ease;
  &.project-open {
    @apply overflow-hidden select-none;
    transform: scale(0.90) translateY(20px);
   
  }
}

 @screen sm {
  .main, .main-app-panel {
     @apply origin-left;
     &.project-open {
      transform: scale(0.95) translateX(20px);
    }
  }
}
</style>
