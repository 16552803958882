<template>
    <router-link :to="`/project/${value.name}`" class="tile">
        <div class="tile-wrapper relative">
            <span class="absolute left-0 -mt-3 ml-3 px-2 py-1 rounded-full font-normal tracking-normal bg-black text-white text-xs" v-if="value.recommended">Recommended project</span>

            <div class="tile-content h-64 overflow-hidden">
                <img class="h-full w-full object-cover" v-if="value.name" :src="`https://cdn.joeltankard.com/website/projects/${value.name}/hero.png`" />
            </div>
            <div class="sm:flex flex-row">
                <div class="tile-content sm:tile-col py-3 px-4 flex-1 relative">

                    <h2>{{ value.title }} </h2>
                    <p>{{ value.shortDescription }}</p>
                </div>
                <router-link :to="`/project/${value.name}`" class="button flex justify-center items-center tile-content sm:tile-col py-3 px-4 sm:w-32">
                    <span class="text-lg sm:hidden">View project</span> <h2 class="hidden sm:block"><i class="uil uil-corner-down-right-alt"></i></h2>
                </router-link>
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    name: 'tile',
    props: {
        value: {
            type: Object,
            required: true
        }
    }
}
</script>
