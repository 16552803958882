<template>
    <div :class="['project-modal', { 'open' : $route.meta.id }]">
        <div class="modal-overview" @click="$router.push('/')" />
        
        <div class="modal-panel" :style="modalStyle" @touchstart="mouseDown"  @touchend="mouseUp" @touchmove="mouseMove" >
            <template v-if="showContent">
                <div class="absolute top-0 inset-x-0 text-center" style="margin-top: -22px;">
                    <div :class="['inline-block w-12 h-1 bg-white rounded opacity-0 duration-200',  { 'opacity-100' : $route.meta.id }]" />
                </div>
                <div ref="scroller" :class="['modal-content', { 'dragging': isDragging }, { 'select-none' : scrollTop <= 0 }]" @scroll="onScroll">
                    <Menu :scroll-top="scrollTop" rounded>
                        <h1 class="md:hidden text-base font-semibold">{{ $route.meta.title }}</h1>
                    <div class="flex items-center">
                        <router-link to="/" class="text-3xl inline-block "><i class="uil uil-times md:hidden"></i><i class="uil uil-arrow-left hidden md:block"></i></router-link>
                        <h1 class="ml-8 hidden md:inline-block text-base font-semibold">{{ $route.meta.title }}</h1>
                    </div>
                    </Menu>
                    <section>
                    
                        <div class="section-content project-page pb-8">

                            <header>
                                <div class="md:flex items-center">
                                    <div class="md:w-1/2">
                                        <h1 class="text-6xl mb-8">{{ $route.meta.shortDescription }}</h1>
                                        <p class="mb-8 text-xl project-description" v-html="$route.meta.description" ></p>
                                    </div>
                                    <div class="md:w-1/2 md:pl-8">
                                        <template v-if="$route.meta.skill">
                                            <p class="mb-2 text-gray-500 text-sm">Skills:</p>
                                            <p class="tags mb-4 text-sm">
                                                <span :key="tag" v-for="(tag, index) in $route.meta.skill">{{ tag }}<template v-if="index < $route.meta.skill.length-1">, </template></span>
                                            </p>
                                        </template>
                                        <template v-if="$route.meta.stack">
                                            <p class="mb-2 text-gray-500 text-sm">Stack:</p>
                                            <p class="tags text-sm">
                                                <span :key="tag" v-for="(tag, index) in $route.meta.stack">{{ tag }}<template v-if="index < $route.meta.stack.length-1">, </template></span>
                                            </p>
                                        </template>
                                        <div class="flex mt-6">
                                            <link-button v-if="$route.meta.website" :href="$route.meta.website">Website</link-button>
                                            <link-button class="ml-2 lg:ml-4" v-if="$route.meta.demo" :href="$route.meta.demo" secondary>Demo</link-button>
                                            <link-button v-if="$route.meta.repo" :href="$route.meta.repo" secondary>Repo</link-button>
                                        </div>
                                    </div>
                                </div>
                            </header>
                            <router-view />
                            <contact-section />
                        </div>
                    </section>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import Menu from '@/components/Menu';
import LinkButton from '@/components/LinkButton';
import ContactSection from '@/components/ContactSection';

export default {
    name: 'project-modal',
    components: {
        Menu,
        LinkButton,
        ContactSection
    },
    data() {
        return {
            scrollTop: 0,
            dragYInit: 0,
            scrollTopInt: 0,
            dragging: false,
            dragY: 0,
            showContent: this.$route.meta.id
        }
    },
    computed: {
        isDragging() {
            return !(!this.dragging || this.dragY <= 0 || this.dragY - this.dragYInit <= 0 || this.scrollTop > 0)
        },
        modalStyle() {
            if(!this.$route.meta.id) return {};
            let y = 0;
            if (this.scrollTop < 0 && !this.dragging) {
                y = Math.abs(this.scrollTop);
            } else if (!this.isDragging ) {
                return {};
            } else {
                y = this.dragY - (this.scrollTopInt + this.dragYInit);
            }
            return {
                transform: `translateY(${y}px)`,
                transition: 'none',
            }
        }
    },
    methods: {
        onScroll({ target }) {
            this.scrollTop = target.scrollTop;
            if (this.scrollTop < -100 && !this.dragging) {
                this.mouseUp()
               return this.$router.push('/')
            }
        },
        mouseDown(event) {
            // if (this.scrollTop > 0) return {};
            this.dragYInit = event.pageY || event.touches[0].pageY;
            this.dragging = true;
            this.scrollTopInt = this.scrollTop;
        },
        mouseMove(event) {
           if (!this.dragging) return;
           if (this.dragY > 0 && this.dragY - this.dragYInit < 0) return this.mouseUp()
           this.dragY = event.pageY || event.touches[0].pageY;
          
        },
        mouseUp() {
            if (this.dragY - this.dragYInit > (window.innerHeight / 3) && this.scrollTop <= 0) {
                this.$router.push('/')
           }
            this.dragging = false;
            this.dragYInit = 0;
            this.dragY = 0;
        }
    },
    watch: {
        '$route.meta.id': {
            handler: function(id) {
                if (!id) {
                    this.showContent = false;
                    return;
                }
                setTimeout(() => {
                    this.showContent = true;
                }, 300)
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style lang="scss" scoped>
.project-modal {
   @apply pointer-events-none fixed inset-0 pt-8 z-50;
    .modal-panel {
        @apply relative bg-white rounded-t-3xl h-full transform translate-y-full;
        -webkit-backface-visibility: hidden;
        transition: .2s ease transform;
    }
    .modal-content {
        @apply overflow-scroll relative h-full rounded-t-3xl;
        -webkit-backface-visibility: hidden;
        transition: .3s ease opacity;
        &.dragging {
            @apply pointer-events-none overflow-hidden h-full;
             -webkit-overflow-scrolling: none;
        }
    }
    .modal-overview {
        @apply bg-black opacity-0 absolute inset-0;
        transition: .2s ease opacity;
    }
    &.open {
        @apply pointer-events-auto;
        .modal-panel {
            @apply translate-y-0;
        }
        .modal-overview {
            @apply opacity-25;
        }
    }

    @screen md {
        @apply pt-0 pl-12;
        .modal-panel {
            @apply rounded-t-none rounded-l-3xl translate-y-0 translate-x-full;
        }
        .modal-content {
            @apply rounded-t-none rounded-l-3xl;
        }
        &.open {
             .modal-panel {
                  @apply translate-x-0;
             }
        }
    }
}
</style>