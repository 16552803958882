<template>
    <section class="hero h-screen relative">
        <div class="relative text-change h-full flex justify-center items-center section-content">
            <div v-if="ready" class="relative w-full bg-gray-300 overflow-hidden flex justify-center items-center">
                <pre-video v-if="!$route.meta.id" src="https://cdn.joeltankard.com/website/hero.mp4" class="absolute inset-0 object-cover" hero @loaded="loaded" />
                <div :class="['hero-text', {'is-ready': ready && heroVideoLoaded}]">
                    <div class="text-wrapper"><div class="d-1"><img src="https://cdn.joeltankard.com/website/hero-text/a1.svg" /></div></div>
                    <span class="a1-text"/>
                    <div class="text-wrapper"><div class="d-2"><img src="https://cdn.joeltankard.com/website/hero-text/software.svg" /></div></div>
                    <span  class="software-text"/>
                    <div class="text-wrapper"><div class="d-3"><img src="https://cdn.joeltankard.com/website/hero-text/engineer.svg" /></div></div>
                    <span  class="engineer-text"/>
                    <div class="text-wrapper"><div class="d-4"><img src="https://cdn.joeltankard.com/website/hero-text/with.svg" /></div></div>
                    <span  class="with-text"/>
                    <div class="text-wrapper"><div class="d-5"><img src="https://cdn.joeltankard.com/website/hero-text/a2.svg" /></div></div>
                    <span  class="a2-text"/>
                    <div class="text-wrapper"><div class="d-6"><img src="https://cdn.joeltankard.com/website/hero-text/background.svg" /></div></div>
                    <span  class="background-text"/>
                    <div class="text-wrapper"><div class="d-7"><img src="https://cdn.joeltankard.com/website/hero-text/in.svg" /></div></div>
                    <span  class="in-text"/>
                    <div class="text-wrapper"><div class="d-8"><img src="https://cdn.joeltankard.com/website/hero-text/design.svg" /></div></div>
                    <span  class="design-text"/>
                </div>
                <div class="absolute bottom-0 inset-x-0 bg-white h-1" />
                 <div class="absolute left-0 inset-y-0 bg-white w-1" />
            </div>
            <div :class="['absolute inset-0 flex justify-center items-center duration-300', {'opacity-0': ready}]">
                <Logo class="logo animate-pulse text-gray-300" />
            </div>
        </div>
       
    </section>
</template>

<script>
import PreVideo from '@/components/PreVideo';
import Logo from '@/components/Logo';

export default {
    name: 'Hero',
    components: {
        PreVideo,
        Logo
    },
    props: {
        ready: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            pageReady: this.$route.meta.id,
            heroVideoLoaded: false
        }
    },
    methods: {
        loaded() {
            this.heroVideoLoaded = true
        }
    }
}
</script>

<style lang="scss" scoped>
.logo {
        width: 80px;
        height: 80px;
    }
.hero {
    margin-top: -80px;
    
}
.pre-video video {
    transform: scaleX(-1);
    @apply w-full;
    max-width: none;
}
.hero-text {
    @apply relative  w-full flex flex-wrap;
    min-width: 285px;
    & > * {
        @apply h-36 inline-block;
        margin: -1px -4px 0 0;
    }
    .text-wrapper {
        @apply overflow-hidden;
        div {
            @apply relative h-full transform translate-y-full;
            transition: 0.8s transform cubic-bezier(0.16, 1, 0.3, 1);
            &:before {
            content: '';
                @apply absolute inset-0 bg-white transform -translate-y-full;
            }
            &.d-1 { transition-delay: 0.3s; }
            &.d-2 { transition-delay: 0.4s; }
            &.d-3 { transition-delay: 0.5s; }
            &.d-4 { transition-delay: 0.6s; }
            &.d-5 { transition-delay: 0.7s; }
            &.d-6 { transition-delay: 0.8s; }
            &.d-7 { transition-delay: 0.9s; }
            &.d-8 { transition-delay: 1s; }
        }
        img {
            @apply h-full;
        }
    }
    
    span {
        @apply bg-white
    }

    &.is-ready {
        .text-wrapper div {
             @apply translate-y-0;
        }
    }
    
     @media (min-width: 1277px) {
          .engineer-text, .background-text, .design-text { @apply flex-grow }
    }

    @media (min-width: 639px) and (max-width: 1276px) {
        .software-text, .a2-text, .in-text, .design-text { @apply flex-grow };
        .background-text { @apply w-1;}
    }
    @media (min-width: 880px) and (max-width: 985px) {
        & > * { @apply h-32; }
    }
    @media (min-width: 775px) and (max-width: 880px) {
        & > * { @apply h-28; }
    }
    @media (min-width: 519px) and (max-width: 775px) {
        & > * { @apply h-24; }
    }
    @media (min-width: 580px) and (max-width: 638px) {
        .software-text, .with-text, .background-text, .design-text { @apply flex-grow };
        .a2-text { @apply w-1;}
    }
    @media (min-width: 520px) and (max-width: 579px) {
        .software-text, .engineer-text, .a2-text, .background-text, .design-text { @apply flex-grow };
    }
    @media (min-width: 438px) and (max-width: 519px) {
        & > * { @apply h-20; }
    }
    @media (min-width: 487px) and (max-width: 519px) {
        .software-text, .with-text, .background-text, .design-text { @apply flex-grow };
    }
    @media (min-width: 438px) and (max-width: 486px) {
        .software-text, .engineer-text, .a2-text, .background-text, .design-text { @apply flex-grow };
    }

    @media (min-width: 357px) and (max-width: 437px) {
        & > * { @apply h-16; }
    }
    @media (min-width: 430px) and (max-width: 437px) {
        .software-text, .a2-text, .in-text, .design-text { @apply flex-grow };
    }
    @media (min-width: 394px) and (max-width: 429px) {
        .software-text, .with-text, .background-text, .design-text { @apply flex-grow };
        .a2-text { @apply w-1;}
    }
    @media (min-width: 357px) and (max-width: 393px) {
        .software-text, .engineer-text, .a2-text, .background-text, .design-text { @apply flex-grow };
    }

     @media (max-width: 356px) {
        & > * { @apply h-14; }
    }
    @media (min-width: 347px) and (max-width: 356px) {
         .software-text, .with-text, .background-text, .design-text { @apply flex-grow };
        .a2-text { width: 3px }
    }
    @media (max-width: 346px) {
        .software-text, .engineer-text, .a2-text, .background-text, .design-text { @apply flex-grow };
    }


    

}
</style>