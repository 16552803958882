<template>
    <div :class="['menu px-4 md:px-8',  { 'rounded-menu': rounded }]">
      <div :class="['menu-background',{ 'background-on': menuBackground }, {'rounded-t-3xl': hideBg}]" />
      <div class="relative  flex justify-between items-center">
        <slot />
      </div>
    </div>
</template>

<script>
export default {
    name: 'Menu',
    props: {
        scrollTop: {
            type: Number,
            required: 0
        },
        rounded: {
            type: Boolean,
            default: false
        },
        hideBg: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        menuBackground() {
            return this.scrollTop > 10
        }
    }
}
</script>

<style lang="scss" scoped>
.menu {
  @apply z-10 py-4 sticky top-0 inset-x-0;
  transition: .3s transform ease;
  .menu-background {
    @apply absolute inset-0 bg-white shadow opacity-0;
    transition: .2s ease opacity;
    background-color: rgba(255,255,255,0.8);
    -webkit-backdrop-filter: blur(100px);
    backdrop-filter: blur(100px);
    &.background-on {
      @apply opacity-100;
    }
  }
  &.rounded-menu, &.rounded-menu .menu-background {
      @apply rounded-t-3xl md:rounded-none;
  }
}
</style>