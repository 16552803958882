<template>
    <a :href="href" target="_blank" :class="['link-button rounded-full border-2 border-black py-4 px-2 w-full lg:text-xl font-bold text-center', { 'bg-black text-white': !secondary }, { 'px-8 w-auto' : inline }]">
        <slot /> <i class="ml-1 uil uil-external-link-alt"></i>
    </a>
</template>

<script>
export default {
    name: 'LinkButton',
    props: {
        href: {
            type: String,
            required: true
        },
        secondary: {
            type: Boolean,
            default: false
        },
        inline: {
            type: Boolean,
            default: false
        },
    }
}
</script>