import Vue from 'vue'
import VueRouter from 'vue-router'
import Projects from '@/assets/projects.js';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
  },
  ...Projects.map((project) => {
    const componentName = project.name.split('-').reduce((string, item) => string + item.charAt(0).toUpperCase() + item.slice(1), '');
    return { 
      path: `/project/${project.name}`, 
      name: componentName,
      meta: { id: project.name, ...project },
      component: ()  => import(`@/views/projects/${componentName}.vue`)
    }
  })
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
