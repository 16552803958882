<template>
  <div class="contact-button text-center py-32 px-8">
    <h1 class="mb-4">Need help on a project?</h1> 
   
    <a class="inline-block max-w-sm mx-auto" href="mailto:hello@joeltankard.com">
        <lottie-player class="mt-8" src="https://assets10.lottiefiles.com/private_files/lf30_ayrb6gs7.json"  background="transparent"  speed="1"  loop autoplay></lottie-player>
    </a>
</div>

</template>

<script>
export default {
    name: 'ContactSection'
}
</script>

<style lang="scss" scoped>
.contact-button {
    svg g path {
        fill: red !important;
    }
}
</style>